import React from "react";
import styles from "../Home.module.css";
interface BreakingRunnerProps {
  breaking: string;
}

const BreakingRunner: React.FC<BreakingRunnerProps> = ({ breaking }) => {
  return (
    <div className={styles.breaking}>
      <span>This just happened!!</span>
      {breaking}
    </div>
  );
};

export default BreakingRunner;
