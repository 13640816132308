import React from "react";
import style from "../Home.module.css";
import { Avatar } from "@mui/material";
type Candidate = {
  name: string;
  age: number;
  votes: number;
};

type WeeklyGiveawayProps = {
  candidates: Candidate[];
};

const WeeklyGiveaway: React.FC<WeeklyGiveawayProps> = ({ candidates }) => {
  return (
    <div className={style.giveaway}>
      <div className={style.giveawayTitle}>
        <h4>NEW: Vote for the Jrankro of the week for a $25 gift card</h4>
        <img src="./amazon.png" alt="giftcard" />
      </div>

      <ul>
        {candidates.map((candidate, index) => (
          <li key={index}>{candidate.name}</li>
        ))}
      </ul>
      <div className={style.podium}>
        <div className={style.avatar}>
          <b>#1</b>
          <img
            className={style.giveawayPersona1}
            src="https://th.bing.com/th/id/R.6101b0b6938679c65a8e46e1d8730c18?rik=9hyJCvYZ9DnPbw&pid=ImgRaw&r=0"
            alt="giftcard"
          />
          {/* <b>Body Guud</b> */}
        </div>
        <div className={style.avatar}>
          <b>#2</b>
          <img
            className={style.giveawayPersona3}
            src="https://th.bing.com/th/id/R.6101b0b6938679c65a8e46e1d8730c18?rik=9hyJCvYZ9DnPbw&pid=ImgRaw&r=0"
            alt="giftcard"
          />
          {/* <b>Derrik Morgan</b> */}
        </div>
        <div className={style.avatar}>
          <b>#3</b>
          <img
            className={style.giveawayPersona2}
            src="https://th.bing.com/th/id/R.6101b0b6938679c65a8e46e1d8730c18?rik=9hyJCvYZ9DnPbw&pid=ImgRaw&r=0"
            alt="giftcard"
          />
          {/* <b>Candy</b> */}
        </div>
      </div>
    </div>
  );
};

export default WeeklyGiveaway;
