export const news = `When Rosario Lopez was looking to buy her first home last year, she knew her financing options were limited. She had been paid in cash, working as a security guard in Los Angeles, and didn’t have W-2s. Her credit history was short. So she wasn’t surprised when her bank, Bank of America Corp., told her she wouldn’t qualify for a mortgage.

Lopez, a single mother with three daughters, looked elsewhere. She found Miracle Lender Inc., a San Fernando Valley mortgage brokerage that pitches itself to Latino borrowers with social media advertising in Spanish. “Credit doesn’t matter,” a loan officer for the company says in an Instagram post.

Miracle Lender found a way to get a mortgage for Lopez without involving a bank. It arranged a $412,000 government-backed loan through GenHome Mortgage Corp., a nonbank lender, so Lopez could buy a four-bedroom ranch-style house in Lancaster, a city about 70 miles north of Los Angeles.

But the deal would cost her. She paid $21,533 in total fees, federal data show. That included almost $10,000 in origination charges that went to the lender and broker, as itemized in her mortgage documents, five times the average amount paid by traditional bank borrowers for loans of the same type and similar size in Los Angeles County last year. Most eye-popping: an $8,275 fee for Miracle Lender.

“It’s a lot,” Lopez says of the fees. “But it’s the only way I can get a house.”

Over the past several years, millions of Americans have faced a similar choice as commercial banks pulled back from the $12 trillion US residential mortgage market. They left borrowers to navigate an opaque and complex financial ecosystem dominated by nonbank lenders that don’t take deposits like traditional banks, operate mostly online and are subject to fewer federal regulations. These lenders depend on fee income more than banks, and they often work with independent service providers, including mortgage brokers, which can add to borrowers’ costs. The result: Homebuyers with the least means, often Black or Latino, pay higher fees.`;
