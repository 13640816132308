import React from "react";
import styles from "../Home.module.css";
import WeeklyGiveaway from "./WeeklyGiveaway";
const Hero: React.FC = () => {
  return (
    <div className={styles.hero}>
      <img
        src="https://s3.amazonaws.com/heights-photos.bcheights.com/wp-content/uploads/2018/04/03142725/j-cole-online.jpg"
        alt="React Logo"
      />
      <WeeklyGiveaway candidates={[]} />
    </div>
  );
};

export default Hero;
