import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./navstyles.module.css";
const Navbar: React.FC = () => {
  return (
    <div className={styles.navContainer}>
      <img src="/jrankro.png" alt="React Logo" />
      <div className={styles.nav}>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">News</NavLink>
        <NavLink to="/contact">Entertainment</NavLink>
        <NavLink to="/contact">Fashion</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </div>
    </div>
  );
};

export default Navbar;
