import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import News from "./News";
import Home from "./Home";

function App() {
  //create browser router here
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { index: true, element: <Home /> },
        { path: "/about", element: <News /> },
        { path: "/contact", element: <h1>Contact</h1> },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
