import React from "react";
import BreakingRunner from "./Home/BreakingRunner";
import Hero from "./Home/Hero";
import styles from "./Home.module.css";
import Feed from "./Feed";
const Home: React.FC = () => {
  return (
    <div className={styles.container}>
      <BreakingRunner breaking="Breaking News" />
      <Hero />
      <div className={styles.subHero}>
        <Feed />
        <div>test</div>
      </div>
    </div>
  );
};

export default Home;
