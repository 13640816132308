import React from "react";

const News: React.FC = () => {
  return (
    <div>
      {/* Add your content here */}
      News
    </div>
  );
};

export default News;
