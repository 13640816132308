import React, { useEffect } from "react";
import styles from "./Feed.module.css";
import { Paper } from "@mui/material";
import { news as test } from "./test";
const Feed: React.FC = () => {
  const [news, setNews] = React.useState<any[]>([]);
  const lineBreak = () => {
    //split after every 3 periods but dont remove the period
    let arr = test.split(/(?<=\.\s)/);
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (i % 6 === 0) {
        //readd the period
        // newArr.push(arr[i] + ".");
        //add a line break
        newArr.push(<br key={i} />);
        //add a space
        newArr.push(<br key={i} />);
      }
      newArr.push(arr[i]);
    }
    setNews(newArr);
    return newArr;
  };

  useEffect(() => {
    lineBreak();
    console.log(news);
  }, []);

  return (
    <Paper className={styles.container}>
      <h1>The Title of the Article</h1>
      <div className={styles.middle}>
        <div>
          <aside>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Suscipit
            adipiscing bibendum est ultricies integer. Risus nec feugiat in
            fermentum posuere urna nec tincidunt.
          </aside>
          <div className={styles.author}>
            <img
              src="https://th.bing.com/th/id/R.ee11b1745c822f0a1fa0bf2ecdd8683a?rik=qV0Q9gpeWlrA%2fw&pid=ImgRaw&r=0"
              alt="Author"
            />
            <div className={styles.contributor}>
              <b>Author</b>
              <b>Tom Dillan</b>
            </div>
          </div>
        </div>
        <img src="/tiktok-jamaica.jpeg" alt="Article" />
      </div>
      <p className={styles.article}>{news}</p>
    </Paper>
  );
};

export default Feed;
